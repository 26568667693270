<template>
  <ElementTiptap
    v-model="item.conteudo"
    width="100%"
    :extensions="extensions"
    lang="pt_br"
  />
</template>

<script>
import { ElementTiptap } from "element-tiptap";
import "element-tiptap/lib/index.css";
import codemirror from "codemirror";

import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  CodeBlock,
  TableHeader,
  TableCell,
  TableRow,
  Table,
  CodeView,
  Image,
  Blockquote,
  Link,
  Iframe,
  TextAlign,
  LineHeight,
  Indent,
  TextColor,
  TextHighlight,
} from "element-tiptap";

export default {
  name: "editor-conteudo",
  components: {
    ElementTiptap,
  },
  props: {
    item: {
      conteudo: "",
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }), // render command-button in bubble menu.
        new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new CodeBlock(),
        new Iframe(),
        new TextHighlight(),
        new TextColor({
          colors: [
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#000000",
          ],
        }),
        new TextAlign({
          alignments: ["left", "center", "right", "justify"],
        }),
        new Table({
          resizable: true,
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Blockquote(),
        new Link(),
        new LineHeight({
          lineHeights: ["100%", "200%", "300%"],
        }),
        new Image(),
        new CodeView({
          codemirror, // the CodeMirror library.
          codemirrorOptions: {
            lineNumbers: true,
            lineWrapping: true,
            tabSize: 2,
            tabMode: "indent",
            mode: "text/html",
          },
        }),
        new Indent({
          minIndent: 0,
          maxIndent: 7,
        }),
      ],
    };
  },
};
</script>

<style>
/* CSS personalizado pode ser adicionado aqui */
.el-message-box__wrapper,
.el-dialog,
.el-tiptap-edit-link-dialog,
.el-dialog__wrapper {
  z-index: 999999 !important;
}
</style>
